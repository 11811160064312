<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="feedback">
                    <van-field v-model="content" rows="5" autosize type="textarea" maxlength="512" placeholder="请输入意见反馈" show-word-limit border style="border:1px solid #e4e7ed;border-radius: 4px;"/>
                </div>
                <div class="fx-column fx-end" style="margin-top: 5px">
                    <van-button type="primary" size="small" @click="submit">提 交</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { submitFeedback } from '@api/permission-request'
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            content: '',
        }
    },
    computed: {
        ...mapGetters([
            'hasGetMemberInfo',
            'authCode',
            'appKey'
        ]),
        memberInfo() {
            return this.$store.state.user.memberInfo;
        },
    },
    methods: {
        submit() {
            submitFeedback({content: this.content}).then((res) => {
                this.$toast('提交成功');
                setTimeout(() => {
                    this.$goPage(-1);
                }, 1500)
            })
        },
        initPage() {
        }
    },
    mounted() {
        this.initPage();
    },
}
</script>

<style lang="scss" scoped>
.fill-box {
	padding: 10px;
}
</style>
